<template>
  <div class="fullwidth">
    <spinner v-show="loading" texto="Cargando, por favor aguarde..."></spinner>
    <listado
      v-show='!loading && !filtro && !mostrarCrearOEditar && !view'
      @ver='ver'
      @crear='crear'
      @editar='editar'
      @mostrarFiltro='mostrarFiltro'
      @loading='setLoading'>
    </listado>
    <crear-o-editar
      v-show='!loading && !filtro && (mostrarCrearOEditar || view)'
      :claseClienteSelected='claseCliente'
      :view='view'
      :edit="edit"
      :agregar='agregar'
      @editar='editar'
      @mostrarListado='mostrarListado'
      @loading='setLoading'>
    </crear-o-editar>
    <filtro
      v-show="!loading && !mostrarCrearOEditar && !view && filtro"
      @mostrarListado='mostrarListado'
    ></filtro>
  </div>
</template>

<script>
import listado from '@/components/clientes-clases/listado.vue'
import crearOEditar from '@/components/clientes-clases/crearOEditar.vue'
import filtro from '@/components/clientes-clases/filtro.vue'
import spinner from '@/components/spinner.vue'
import { EventBus } from '../../event-bus'

export default {
  name: 'ClasesClientes',
  components: {
    listado,
    crearOEditar,
    filtro,
    spinner
  },
  data () {
    return {
      loading: false,
      mostrarCrearOEditar: false,
      filtro: false,
      view: false,
      edit: false,
      agregar: false,
      claseCliente: ''
    }
  },
  methods: {
    crear () {
      this.mostrarCrearOEditar = true
      this.filtro = false
      this.view = false
      this.edit = false
      this.claseCliente = ''
      this.agregar = true
    },
    ver (claseCliente) {
      this.view = true
      this.edit = false
      this.mostrarCrearOEditar = false
      this.filtro = false
      this.claseCliente = claseCliente
      this.agregar = false
    },
    editar (claseCliente) {
      this.mostrarCrearOEditar = true
      this.filtro = false
      this.view = false
      this.edit = true
      this.claseCliente = claseCliente
      this.agregar = false
    },
    mostrarListado () {
      this.tipoUsuario = ''
      this.mostrarCrearOEditar = false
      this.filtro = false
      this.view = false
      this.edit = false
      this.agregar = false
    },
    mostrarFiltro () {
      this.filtro = true
      this.mostrarCrearOEditar = false
      this.view = false
      this.edit = false
      this.agregar = false
    },
    setLoading (value) {
      this.loading = value
    }
  },

  mounted () {
    EventBus.$emit('limpiarToasts')
    EventBus.$emit('toggleShow', true)
    EventBus.$emit('toggleConfiguraciones', false)

    EventBus.$emit('actualizarValorBuscado', '')
  }
}
</script>
