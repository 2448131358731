<template>
  <div class="fullwidth align-left" @click="hideUserOptions()">
    <div class="content-header flex-row flex-row-between">
      <h1>Listado de clases de clientes<span class="cantidad-resultados"> - Resultados: {{clasesClientesFiltrados.length}}</span></h1>

      <div class="content-header-buttons flex-row">
        <div class="content-header-filter flex-row" @click="$emit('mostrarFiltro')">
          <i class="icon-panel"></i>
          <span>Filtrar</span>
        </div>
        <button class="btn-primary shadow animated" @click="$emit('crear')">Agregar nuevo</button>
      </div>
    </div>

    <Chips
      :chips="chips"
      @removeChip="quitarFiltro"></Chips>

    <Table
      :options='tableOptions'
      @ver="ver"
      @editar="editar"
      @cambiarEstado="cambiarEstado"></Table>
  </div>
</template>

<script>
import ajax from '@/ajax'
import { EventBus } from '@/event-bus.js'
import Table from '../table'
import Chips from '../chips'
import ConstantsHelper from '@/ConstantsHelper'

export default {
  name: 'listado',
  components: {
    Table,
    Chips
  },
  data () {
    return {
      clasesClientes: [],
      clasesClientesFiltrados: [],
      filtros: {
        text: '',
        estado: '1'
      },

      chips: {
        text: {
          show: false,
          indicador: 'Palabra Clave',
          chip: '',
          title: 'Eliminar este filtro'
        },
        estado: {
          show: true,
          indicador: 'Estado',
          chip: 'Activos',
          title: 'Eliminar este filtro'
        }
      },

      tableOptions: {
        columns: [
          {
            name: 'Nombre',
            width: 90,
            rowProperty: 'nombre',
            hideMobile: false
          }
        ],
        options: true,
        rows: [],
        buttons: [
          {
            name: 'EDITAR',
            fn: 'editar'
          }
        ]
      }
    }
  },
  methods: {
    cambiarEstado (data) {
      let clasesCliente = data.obj
      let index = data.index
      let action = data.action
      const actionName = (action === 1) ? 'Activará' : 'Desactivará'
      if (confirm(`Se ${actionName} la clase de cliente. ¿Desea continuar?`)) {
        this.$emit('loading', true)

        const nuevoEstado = (clasesCliente.state === 1) ? 0 : 1

        const nuevoClasesCliente = {
          id: clasesCliente.id,
          nombre: clasesCliente.nombre,
          estado: nuevoEstado
        }

        ajax('PUT', ConstantsHelper.getURLs().v1 + 'clientes-clases.php', { claseCliente: nuevoClasesCliente, action: 'cambiarEstado', usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
          .then((response) => {
            let type = 'error'
            if (response.status === 1) {
              this.clasesClientesFiltrados[index].state = nuevoEstado
              this.ordenarPorEstado()
              this.filtrar()
              type = 'correct'
            }
            EventBus.$emit('generateToast', {
              message: response.message,
              type: type
            })

            this.$emit('loading', false)
          })
          .catch(() => {
            this.$emit('loading', false)
          })
      }
    },

    filtrar () {
      this.clasesClientesFiltrados = this.clasesClientes
      this.clasesClientesFiltrados = this.filtrarText()
      this.clasesClientesFiltrados = this.filtrarEstado()

      this.ordenarPorEstado()
    },
    filtrarText () {
      return this.clasesClientesFiltrados.filter((cc) => {
        return (cc.nombre.toLowerCase().indexOf(this.filtros.text) !== -1)
      })
    },
    filtrarEstado () {
      return this.clasesClientesFiltrados.filter((u) => {
        return (this.filtros.estado === '' || u.state === parseInt(this.filtros.estado))
      })
    },
    filtroRapido (text) {
      this.filtros.text = text
      this.actualizarChips()
      this.filtrar()
    },

    quitarFiltro (field) {
      this.filtros[field] = ''
      this.filtrar()

      if (field === 'text') {
        EventBus.$emit('actualizarValorBuscado', '')
      }

      EventBus.$emit('actualizarFiltros', this.filtros)
    },

    ordenarPorEstado () {
      this.clasesClientesFiltrados.sort((a, b) => {
        if (a.state === 0) {
          return 1
        }
        if (b.state === 0) {
          return -1
        }
        return a.nombre - b.nombre
      })
    },

    parseClaseCliente (claseCliente) {
      return {
        id: claseCliente.id,
        nombre: claseCliente.nombre,
        state: claseCliente.state,
        mostrarMenu: false
      }
    },
    hideUserOptions () {
      for (let claseCliente of this.clasesClientes) {
        claseCliente.mostrarMenu = false
      }
    },
    ver (claseCliente) {
      this.$emit('ver', claseCliente)
    },
    editar (claseCliente) {
      this.$emit('editar', JSON.parse(JSON.stringify(claseCliente)))
    },

    actualizarChips () {
      this.chips.text.chip = this.filtros.text
      this.chips.text.show = this.filtros.text !== ''

      this.chips.estado.chip = (this.filtros.estado === '1') ? 'Activos' : 'Desactivados'
      this.chips.estado.show = this.filtros.estado !== ''
    },

    actualizarAltoTabla () {
      let header = document.getElementsByClassName('main-content-header')[0]
      let contentHeader = document.getElementsByClassName('content-header')[0]
      let chipsContainer = document.getElementsByClassName('chips-container')[0]

      if (header !== undefined && contentHeader !== undefined && chipsContainer !== undefined) {
        EventBus.$emit('actualizarAltoTabla', [
          header.getBoundingClientRect().height,
          contentHeader.getBoundingClientRect().height,
          chipsContainer.getBoundingClientRect().height
        ])
      }
    }
  },
  mounted () {
    EventBus.$on('claseClienteAgregado', (claseCliente) => {
      this.clasesClientes.push(claseCliente)
      this.filtrar()
    })
    EventBus.$on('claseClienteEditado', (claseCliente) => {
      const index = this.clasesClientes.findIndex(u => u.id === claseCliente.id)
      this.clasesClientes[index] = claseCliente
      this.filtrar()
    })
    EventBus.$on('filtroRapidoClasesclientes', this.filtroRapido)
    EventBus.$on('filtrar', (filtroData) => {
      this.filtros = filtroData
      this.actualizarChips()
      this.filtrar()
    })
    this.$emit('loading', true)

    ajax('GET', ConstantsHelper.getURLs().v1 + 'clientes-clases.php?estado=%')
      .then((response) => {
        let type = 'error'
        if (response.code === 1) {
          response.data.forEach(cc => {
            this.clasesClientes.push(this.parseClaseCliente(cc))
          })
          this.filtrar()
          this.$emit('loading', false)
          type = 'correct'
        } else {
          EventBus.$emit('generateToast', {
            message: response.response,
            type: type
          })
        }
      })
  },
  watch: {
    clasesClientes () {
      this.actualizarAltoTabla()
    },
    clasesClientesFiltrados () {
      this.tableOptions.rows = this.clasesClientesFiltrados
    }
  }
}
</script>

<style>

</style>
